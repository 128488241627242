const axios = require("axios");
const successRegex = new RegExp(/2../);
export const get = async (url) => {
  let headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  const conf = {
    method: "GET",
    mode: "cors",
  };
  try {
    const req = new Request(url);
    let data = await fetch(req, conf);
    if (!successRegex.test(data.status)) {
      const { err } = await data.json();
      throw err;
    }
  } catch (err) {
    alert(
      "If you are using Internet Explorer, please switch to other browser like Chrome, Edge or Firefox"
    );
    throw err;
  }
};

export const post = async (url, body = {}) => {
  let headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  try {
    body = JSON.stringify(body);
    const conf = {
      method: "POST",
      mode: "cors",
      body,
      headers,
    };
    const req = new Request(url);
    let data = await fetch(req, conf);
    if (!successRegex.test(data.status)) {
      const { err } = await data.json();
      throw err;
    }
  } catch (err) {
    alert(
      "If you are using Internet Explorer, please switch to other browser like Chrome, Edge or Firefox"
    );
    throw err;
  }
};
