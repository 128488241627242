export const nameRx = new RegExp(/^\w[\w\s'",!.]{0,50}$/);
export const allRx = new RegExp(/^\w[\w.]{0,30}$/);
export const emailRx = new RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
export const trueRx = new RegExp(/^true$/i);

export const form_comps = {
  firstname: {
    id: "firstname",
    lbl: "First Name",
    placeholder: "Enter First Name",
    rx: nameRx,
    err_msg: "First name should not contain special characters",
  },
  email: {
    id: "email",
    lbl: "Email Address",
    placeholder: "Enter Email Address",
    rx: emailRx,
    err_msg: "Enter valid email address",
  },
  instausername: {
    id: "instausername",
    lbl: "Instagram UserID",
    placeholder: "Enter your instagram username myinstausername",
    rx: allRx,
    err_msg: "Enter valid instagram username",
  },
  eighteen_old: {
    id: "eighteen_old",
    lbl: "I am 18-years or older",

    rx: trueRx,
    err_msg: "You must be Eighteen (18) years to participate",
    type: "checkbox",
  },
};

//const server_address = "http://localhost:7005/";
const server_address = "https://d38370hyqouf66.cloudfront.net/";

export const API = {
  userinfo: server_address + "instaform/userinfo",
  view: server_address + "instaform/view",
  click: server_address + "click/email_click",
};
