import React from "react";
import TextBox from "./TextBox";
import { form_comps } from "../assets/const";
import { submit_userinfo, updateHandler } from "../assets/FormAction";
import { Link } from "react-router-dom";
export default ({ setSubmitted }) => {
  const onSubmit = () => {
    const is_valid = submit_userinfo();
    if (is_valid) {
      setSubmitted(true);
    } else {
      alert("Please fill all required fields");
    }
  };
  return (
    <div className="form-wrapper">
      <h3>If you haven’t yet, follow @springbokpuzzles on Instagram</h3>
      <div>
        <p>
          Find our profile by searching “Springbok Puzzles” on the website or
          app. One there, click the blue follow button. You must have an
          Instagram to complete this step. If you do not have an Instagram
          account, you will be prompted to create one.
        </p>
        <a className="insta-btn" target="_blank" href="https://www.instagram.com/springbokpuzzles/" rel="nofollow">Visit Springbok’s Instagram Profile</a>
        <p>
          After you follow @springbokpuzzles on Instagram, complete the
          following fields in the form below. The Free Mystery Puzzle code will
          arrive within 24 hours of submission.
        </p>
      </div>
      {Object.values(form_comps).map((f) => (
        <TextBox key={f.id} {...f} updateHandler={updateHandler} />
      ))}
      <div className="submit-btn-wrapper">
        <button className="submit-btn" onClick={onSubmit}>
          SEND MY FREE MYSTERY PUZZLE CODE
        </button>
      </div>
      <div className="need-help">
        <Link to="/help">Need Help?</Link>
      </div>
    </div>
  );
};
