import React, { useState } from "react";
import Form from "./Form";
import SuccessMsg from "./SuccessMsg";

export default () => {
  const [form_submitted, setSubmitted] = useState(false);
  return !form_submitted ? (
    <Form setSubmitted={setSubmitted} />
  ) : (
    <SuccessMsg />
  );
};
