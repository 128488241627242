import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import logo from "./assets/header.jpg";
import MainPage from "./components/MainPage";
import FAQ from "./components/FAQ";
import { post_view } from "./assets/tracker";
import "./App.css";

function App() {
  useEffect(() => {
    post_view();
  }, []);
  return (
    <div className="App">
      <header>
        <div>
          <img src={logo} />
        </div>
        {/* <h1>Welcome to Instacontest by Springbok Puzzles</h1> */}
      </header>
      <Router>
        <Switch>
          <Route path="/help">
            <FAQ />
          </Route>
          <Route path="/">
            <MainPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
