import { post } from "../assets/request";
import { API } from "./const";
export const post_view = () => {
  try {
    if (window.location.pathname.includes("utm")) {
      post_click();
    } else {
      post(API.view);
    }
  } catch {}
};

export const post_click = () => {
  try {
    const re = /click_code=(\w{1,50})/i;
    const url = window.location.href;
    const contact_id = url.match(re)[0];    
    const body = { contact_id, url };
    post(API.click, body);
  } catch {}
};
