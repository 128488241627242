import React from "react";

export default () => (
  <div className="success-msg">
    <h3>Thank you!</h3>
    <p>Your code will arrive in your inbox within the next 24 hours.</p>
    <p>
      Watch your inbox for the email containing your Free Mystery Puzzle code.
      The code will arrive within 24 hours of submission. Be sure to check your
      spam folder in case the email is archived there.
    </p>
    <p>
      Your Free Mystery code will be valid for an order of $12.50+ and does not
      include free shipping. You must add a Mystery Puzzle to cart and apply the
      Free Mystery Puzzle code at checkout to redeem the offer. Limit one per
      customer.<i> Offer available while supplies last.</i>
    </p>
  </div>
);
