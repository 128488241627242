import React from "react";
import "../faq.css";
export default () => (
  <div className="faq-wrapper">
    <h1>HELP</h1>
    <div className="faq">
      <h4>What if I don’t have an Instagram account?</h4>
      <p>
        In order to participate in the promotion, you must have an Instagram
        account. To create an Instagram account, complete the steps as listed on
        <a
          href="https://help.instagram.com/1642053262784201"
          target="_blank"
          rel="nofollow"
        >
          {" "}
          Instagram.
        </a>
      </p>
    </div>

    <div className="faq">
      <h4>When will my code arrive?</h4>
      <p>
        Springbok Puzzles is verifying your participation. Your code will arrive
        in your inbox within the next 24 hours.
      </p>
      <p>
        Watch your inbox for the email containing your Free Mystery Puzzle code.
        The code will arrive within 24 hours of submission. Be sure to check
        your spam folder in case the email is archived there.
      </p>
    </div>

    <div className="faq">
      <h4>24 hours has passed. I have not received an email.</h4>
      <p>
        Please check your spam folder in case the email is archived there. If
        you have not received an email, please
        <ul className="step">
          <li>
            1. Email{" "}
            <a href="mailto:info@springbok-puzzles.com">
              info@springbok-puzzles.com
            </a>{" "}
            with the subject line “Have Not Received Free Mystery Puzzle Code”
          </li>
          <li>
            2. Include your name and Instagram username. Anticipate a response
            within 5 business days.
          </li>
        </ul>
      </p>
    </div>
    <div className="faq">
      <h4>How can I redeem my Free Mystery Puzzle code?</h4>
      <ol>
        <li>
          <p>
            <a
              href="https://www.springbok-puzzles.com/product-p/33-misc.htm"
              target="_blank"
              style={{ letterSpacing: "0px" }}
            >
              Add 1 Mystery Jigsaw Puzzle (33-MISC) to your cart.
            </a>{" "}
            Do this by clicking the "Add to Cart" button above.
          </p>
        </li>
        <img src="https://dacq68pa0iusn.cloudfront.net/Springbok/Puzzles/33-MISC-Mystery/33-MISC-Mystery-Step1.png" />
        <br />
        <p>
          2. Your cart with 1 Mystery Jigsaw (33-MISC) will look like the
          screenshot below. The total will show $10.85 (tax included).
          <br />
          <img src="https://dacq68pa0iusn.cloudfront.net/Springbok/Puzzles/33-MISC-Mystery/33-MISC-Mystery-Step2.png" />
        </p>
      </ol>
      <span>
        <p>
          3.
          <b>
            Your cart value must be $12.50+ to receive a Free Mystery Puzzle.{" "}
          </b>
          In the cart example below, you'll see a puzzle has been added to the
          cart. The cart value of $13.95 meets the $12.50+ requirement. <br />
          <img src="https://dacq68pa0iusn.cloudfront.net/Springbok/Puzzles/33-MISC-Mystery/33-MISC-Mystery-Step3.png" />
        </p>
        <p>
          4.
          <b>A code is needed.</b> Enter your code in the Coupon Code text box,
          then select the Apply button. The discount will appear if{" "}
          <span>
            1 Mystery Jigsaw (33-MISC)<i> and </i>the cart value is $12.50 or
            more.{" "}
          </span>
          <br />
          <img src="https://dacq68pa0iusn.cloudfront.net/Springbok/Puzzles/33-MISC-Mystery/33-MISC-Mystery-Step4.png" />
        </p>
        <p>
          5.The cost of the Mystery Jigsaw Puzzle will be removed from the
          total.
          <img src="https://dacq68pa0iusn.cloudfront.net/Springbok/Puzzles/33-MISC-Mystery/33-MISC-Mystery-Step5.png" />
        </p>
      </span>
    </div>

    <div className="disclaimer">
      <b>Disclaimer</b>
      <p>
        Your Free Mystery code will be valid for an order of $12.50+ and does
        not include free shipping. You must add a Mystery Puzzle to cart and
        apply the Free Mystery Puzzle code at checkout to redeem the offer.
        Limit one per customer. Offer available while supplies last.{" "}
      </p>
    </div>
  </div>
);
