import { API } from "../assets/const";
import { post } from "./request";
import { form_comps } from "../assets/const";
let formdata = {};
export const updateHandler = (k, v) => {
  try {
    if (typeof v === "string") {
      v = v.toLowerCase().trim();
    }
    formdata[k] = v;
  } catch {
    alert("Please fill all information");
  }
};

export const validate_form = () => {
  try {
    let is_valid = true;
  
    for (const k of Object.keys(form_comps)) {
      debugger
      const { rx } = form_comps[k];
      const val = formdata[k];
      if (val) {
        if (typeof val === "boolean" && !val) {
          is_valid = false;
          break;
        } else if (!rx.test(val)) {
          is_valid = false;
          break;
        }
      } else {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  } catch {
    return false;
  }
};

export const submit_userinfo = () => {
  try {
    const is_valid = validate_form();
    if (is_valid) {
      const body = { ...formdata };
      post(API.userinfo, body);
    }
    return is_valid;
    // const is_valid = validate_form();
    // if (is_valid) {
    //   const body = { ...formdata };
    //   await post(API.userinfo, body);
    //   return true;
    // } else {
    //   return false;
    // }
  } catch {
    return false;
  }
};
